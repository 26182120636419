import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import JSZip from 'jszip'
import { ESPLoader, Transport } from 'esptool-js'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { I18n } from 'aws-amplify'
import {
  CommandLineIcon,
  BoltIcon,
  ShieldCheckIcon
} from '@heroicons/react/24/solid'

import 'xterm/css/xterm.css'

import BaudratesSelect from './BaudratesSelect'
import TableContents from './TableContents'

import { getDevice } from '../../../reducers/selectors'

const BIN_FILES = [
  'bootloader.bin',
  'partition-table.bin',
  'nvs_partition.bin',
  'ota_data_initial.bin'
]
const SELF_TEST_BIN_FILES = [
  'bootloader.bin',
  'partition-table.bin',
  'ota_data_initial.bin'
]

let term = null
const fitAddon = new FitAddon()
let port = null
let transport = null

const StepFlash = ({ onFlashFinish }) => {
  const device = getDevice()

  // Table specific
  const [memoryAddresses, setMemoryAddresses] = useState([])
  const [binFiles, setBinFiles] = useState([])

  // Utils
  const [connected, setConnected] = useState(false)
  const [loadingFiles, setLoadingFiles] = useState(true)
  const [flashingCompleted, setFlashingCompleted] = useState(false)
  const [encryptionCompleted, setEncryptionCompleted] = useState(false)

  // ESP specific
  const [baudrates, setBaudrates] = useState('921600')
  const [fileData, setFileData] = useState([])
  const [firmwareFileName, setFirmwareFileName] = useState(null)
  const [espLoader, setEspLoader] = useState(null)
  const [chip, setChip] = useState(null)
  const [writeEncryptionLog, setWriteEncryptionLog] = useState(false)

  const terminalRef = useRef(null)

  const logEncryptionCallback = useCallback(logEncryption, [writeEncryptionLog])

  useEffect(() => {
    term = new Terminal({
      cols: 75,
      rows: 20,
      theme: {
        foreground: '#d2d2d2',
        background: '#2b2b2b'
      }
    })

    term.loadAddon(fitAddon)

    term.open(terminalRef.current)

    fitAddon.fit()

    return () => {
      term.dispose()
      closePort()
      cleanupEsp()
    }
  }, [])

  useEffect(() => {
    if (device?.zipUrl) {
      unzipFirmwareFiles(device)
    }
  }, [device])

  useEffect(() => {
    if (flashingCompleted && encryptionCompleted) {
      onFlashFinish()
    }
  }, [flashingCompleted, encryptionCompleted, onFlashFinish])

  useEffect(() => {
    logEncryptionCallback()
  }, [logEncryptionCallback])

  async function onClickConnectDevice(e) {
    try {
      e.preventDefault()

      await closePort()

      port = await navigator.serial.requestPort({})
      transport = new Transport(port)

      const espLoaderTerminal = {
        clean() {
          term.clear()
        },
        writeLine(data) {
          term.writeln(data)
        },
        write(data) {
          term.write(data)
        }
      }

      const newEspLoader = new ESPLoader(
        transport,
        baudrates,
        espLoaderTerminal
      )

      const newChip = await newEspLoader.main_fn()

      setEspLoader(newEspLoader)
      setChip(newChip)
      setConnected(true)
    } catch (err) {
      console.log(err)
      term.writeln(`Error: ${err.message}`)
    }
  }

  async function onClickDisconnectDevice(e) {
    e.preventDefault()

    await closePort()
    cleanupEsp()
    term.clear()
  }

  async function onClickFlash(e) {
    e.preventDefault()

    const flashPs1Content = fileData['flash.ps1']

    const memoryAddresses = flashPs1Content
      .match(/0x[0-9a-fA-F]+/g)
      .map(addr => parseInt(addr))

    let fileArray = binFiles.map((binFile, index) => {
      return {
        data: fileData[binFile],
        address: memoryAddresses[index]
      }
    })

    try {
      await espLoader.write_flash(
        fileArray,
        'keep',
        'dio',
        '40m',
        false,
        true,
        undefined,
        undefined
      )

      setFlashingCompleted(true)
    } catch (e) {
      term.writeln(`Error: ${e.message}`)
      console.log('process.env.NODE_ENV', process.env.NODE_ENV)
      if (process.env.NODE_ENV === 'development') {
        setFlashingCompleted(true)
      } else {
        term.writeln('Flashing failed, please try again.')
      }
    }
  }

  async function onClickEncrypt(e) {
    try {
      e.preventDefault()
      setFlashingCompleted(true)
      await closePort()

      console.log('closing ports ...')

      port = await navigator.serial.requestPort({})
      transport = new Transport(port)
      await transport.connect()

      setWriteEncryptionLog(true)

      term.writeln('Starting encryption ...')

      await new Promise(resolve => setTimeout(resolve, 100))
      await transport.setDTR(false)
      await new Promise(resolve => setTimeout(resolve, 100))
      await transport.setDTR(true)

      setEncryptionCompleted(true)
      setWriteEncryptionLog(false)

      term.writeln('Finished encryption ...')
    } catch (err) {
      console.log(err)
    }
  }

  function cleanupEsp() {
    setFirmwareFileName(null)
    setFileData([])
    setEspLoader(null)
    setChip(null)
    setConnected(false)
  }

  async function closePort() {
    try {
      if (transport) {
        await transport.disconnect()
      }

      transport = null
      port = null
    } catch (err) {
      console.log(err)
    }
  }

  async function logEncryption() {
    while (writeEncryptionLog) {
      try {
        const val = await transport.rawRead()
        if (typeof val !== 'undefined') {
          term.write(val)
        } else {
          break
        }
      } catch (err) {
        console.log('err -----', err)
        break
      }
    }
  }

  async function unzipFirmwareFiles(device) {
    try {
      const response = await axios({
        url: device.zipUrl,
        responseType: 'blob',
        method: 'GET'
      })

      const zip = await JSZip.loadAsync(response.data)
      let newFileData = {}
      for (const [name, content] of Object.entries(zip.files)) {
        newFileData[name] = await content.async('binarystring')
      }

      const flashPs1Content = newFileData['flash.ps1']
      const memoryAddresses = flashPs1Content
        .match(/0x[0-9a-fA-F]+/g)
        .map(addr => parseInt(addr))

      const firmwareFileName = flashPs1Content.match(
        /(\d+x\d+ ([a-z_-]+\.bin))(?!.*\d+x\d+ [a-z_-]+\.bin)/
      )[2]

      setFirmwareFileName(firmwareFileName)
      setFileData(newFileData)
      setMemoryAddresses(memoryAddresses)

      if (firmwareFileName && firmwareFileName.toLowerCase().includes("self_test")) {
        setBinFiles([...SELF_TEST_BIN_FILES, firmwareFileName])
      } else {
        setBinFiles([...BIN_FILES, firmwareFileName])
      }

      setLoadingFiles(false)
    } catch (err) {
      console.log(err)
    }
  }

  function onSelectDeviceBaudrate(baudrates) {
    setBaudrates(baudrates.name)
  }

  return (
    <div className='mt-2'>
      {loadingFiles && (
        <h3 className='font-semibold text-lg'>
          {I18n.get('Loading files ...')}
        </h3>
      )}
      <TableContents memoryAddresses={memoryAddresses} binFiles={binFiles} />
      <div>
        <BaudratesSelect
          disabled={loadingFiles}
          onSelectDeviceBaudrate={onSelectDeviceBaudrate}
        />
      </div>
      <div className='flex flex-start gap-2'>
        {flashingCompleted && encryptionCompleted && (
          <h3 className='font-semibold text-lg text-teal-700 pt-2'>
            {I18n.get('Flashing device completed.')}
          </h3>
        )}
        {!(flashingCompleted && encryptionCompleted) && (
          <button
            disabled={loadingFiles}
            onClick={
              !connected ? onClickConnectDevice : onClickDisconnectDevice
            }
            className={`px-4 py-2 mt-4 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2 ${loadingFiles ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            {!connected
              ? I18n.get('Connect device')
              : I18n.get('Disconnect device')}
            <CommandLineIcon className='h5 w-5 self-center' />
          </button>
        )}
        {connected && !flashingCompleted && (
          <button
            onClick={onClickFlash}
            className='px-4 py-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700 font-bold text-sm flex gap-2'
          >
            {I18n.get('Flash device')}
            <BoltIcon className='h5 w-5 self-center' />
          </button>
        )}
        {connected && flashingCompleted && !encryptionCompleted && (
          <button
            onClick={onClickEncrypt}
            className='px-4 py-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-700 font-bold text-sm flex gap-2'
          >
            {I18n.get('Encrypt device')}
            <ShieldCheckIcon className='h5 w-5 self-center' />
          </button>
        )}
      </div>
      <div className='mt-2'>
        {chip && (
          <h3 className='font-semibold text-lg'>
            {chip} {I18n.get('connected successfully.')}
          </h3>
        )}
        <div ref={terminalRef} />
      </div>
    </div>
  )
}

export default StepFlash
